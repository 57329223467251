import { DBFactory } from "classes/database/db_factory";
import { ResourceType } from "~/types/enums/ResourceType.enum";
import { BaseModel } from "../base.model";

import {
  EducationResource,
  FileResource,
} from "../education-resources/education-resource.model";

export type AssignmentResources = AssignmentResource[];

type AssignmentResourceConstructorParams = {
  id?: string;
  assignmentId: string;
  classroomId: string;
  name: string;
  userId: string;
  resource: EducationResource;
  createdAtTimestamp: number;
};

export class AssignmentResource extends BaseModel {
  id?: string;
  assignmentId: string;
  classroomId: string;
  name: string;
  userId: string;
  resource: EducationResource;
  createdAtTimestamp: number;

  constructor(data: AssignmentResourceConstructorParams) {
    super(data);

    this.id = data.id;
    this.assignmentId = data.assignmentId;
    this.classroomId = data.classroomId;
    this.name = data.name;
    this.userId = data.userId;
    this.resource = data.resource;
    this.createdAtTimestamp = data.createdAtTimestamp;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/assignments/${this.assignmentId}/resources`,
      path: `/assignments/${this.assignmentId}/resources/${this.id}`,
    };
  }

  static default(
    userId: string,
    classroomId: string,
    assignmentId: string,
    name: string,
    educationResource: EducationResource
  ) {
    return new AssignmentResource({
      classroomId: classroomId,
      assignmentId: assignmentId,
      userId: userId,
      name: name,
      resource: educationResource,
      createdAtTimestamp: new Date().getTime(),
    });
  }

  static fromMap(map: any) {
    map.resource = EducationResource.fromMap(map.resource);
    return new AssignmentResource(map);
  }

  toMap(): ModelDatabaseData {
    return super.toMap();
  }

  async save() {
    const db = DBFactory.createDatabase();

    await this.resource.save();

    try {
      this.id = await db.save(this.toMap(), this.databaseConfig);
    } catch (error) {}
  }

  async delete() {
    const db = DBFactory.createDatabase();
    // await db.delete(this.databaseConfig);

    // Ok before that I want to delete the file from storge if I'm on the client side.
    if (process.client && this.resource.type === ResourceType.file) {
      (this.resource as FileResource).delete();
    }

    await db.delete(this.databaseConfig);
  }
}
